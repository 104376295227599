/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const state = {
  message: "",
  messageProps: null,
  type: "success",
  showing: false,
  hideable: true
};

const actions = {
  showFlashMessage(
    { commit, dispatch },
    { message, type, messageProps = null, hideable = true }
  ) {
    commit("setMessage", { message, type, messageProps, hideable });
    commit("setShowing", true);
    setTimeout(() => {
      dispatch("hideFlashMessage");
    }, 5000);
  },
  hideFlashMessage({ commit }) {
    commit("setShowing", false);
    commit("setMessage", {
      message: "",
      messageProps: null
    });
  }
};

const mutations = {
  setMessage(state, { message, type, messageProps, hideable }) {
    state.message = message;
    state.type = type;
    state.messageProps = messageProps;
    state.hideable = hideable;
  },
  setShowing(state, flag) {
    state.showing = flag;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
